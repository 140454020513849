import { getNodesBounds, getViewportForBounds, Position } from "@xyflow/react";
import { toPng } from "html-to-image";

import { WorkflowOptionsType } from "@hooks/useWorkflowOptions";

import { NodeNameTemplateFieldMap, NodeNameTemplateTypeMap } from "@constants/templates";

import { validNodesByDefault } from "@utils/flow/nodeDataFabric";

import {
  EmptyTriggerNode,
  EntryNodes,
  FinishNode,
  Flow,
  FlowEdge,
  FlowNode,
  FlowNodeData,
  NodeActionType,
  NodeName,
  NodeType
} from "@/constants/flow";
import { zeroIfNaN } from "@/utils";
import { createEdge, createNode } from '@/utils/flow/creators';
import { by, extract, update } from '@/utils/functions';

export const FlowService = {
  createEmpty: (): Flow => {
    const entry = createNode({
      type: NodeType.DEFAULT,
      data: {
        actionType: NodeActionType.ENTRY_POINT,
      }
    }) as EmptyTriggerNode;
    const finish = createNode({
      type: NodeType.FINISH,
      data: {
        name: NodeName.END_PATH,
        label: `Exit point 1`,
      }
    }) as FinishNode;
    const edge = createEdge({ source: entry.id, target: finish.id });

    return {
      nodes: [entry, finish],
      edges: [edge],
    };
  },
  insertNodeAfter: (flow: Flow, source: string, node: FlowNode) => {
    const nodes = flow.nodes.concat(node);
    const edges = flow.edges
      .filter(({ target: t, source: s }) => !(source === s))
      .concat([
        createEdge({ target: node.id, source }),
        ...flow.edges
          .filter(({ target: t, source: s }) => source === s)
          .map(({ target }) => createEdge({ target, source: node.id }))
      ])

    return { nodes, edges };
  },
  insertNodeBefore: (flow: Flow, { target }: Pick<FlowEdge, 'target'>, node: FlowNode): Flow => {
    const nodes = flow.nodes.concat(node);
    const [source] = FlowService.getParentNodes(flow, target);
    const edges = flow.edges
      .filter(({ target: t, source: s }) => !(source === s && target === t))
      .concat([
        createEdge({ target: node.id, source }),
        createEdge({ target, source: node.id })
      ]);

    if (node.data.name === NodeName.AB_TEST) {
      const [a, b] = node.data.segments.map((segment, index) => createNode({ type: NodeType.SPLITTER, data: { ...segment, order: index * 200 } }));

      return FlowService.insertBranchAfter(
        FlowService.insertNodeBefore({ nodes, edges }, { target, source: node.id }, a),
        { source: node.id },
        b
      );
    }

    if (node.data.name === NodeName.GROUP_SPLIT) {
      const [first, ...segments] = node.data.segments.map((segment, index, self) => createNode({ type: NodeType.SPLITTER, data: { ...segment, order: index * 200 } }));

      let newFlow = FlowService.insertNodeBefore({ nodes, edges }, { target, source: node.id }, first);

      segments.forEach(segment => {
        newFlow = FlowService.insertBranchAfter(newFlow, { source: node.id }, segment);
      });

      newFlow = FlowService.insertBranchAfter(newFlow, { source: node.id }, createNode({ type: NodeType.SPLITTER, data: { label: 'Everyone else', actionType: 'exclude', name: NodeName.EXCLUDE, order: 30000 } }));

      return newFlow;
    }

    return { nodes, edges };
  },
  insertBranchAfter: (flow: Flow, { source }: Pick<FlowEdge, 'source'>, node: FlowNode): Flow => {
    const elementNumber = zeroIfNaN(flow.nodes?.filter((node) => node.type === NodeType.FINISH).length) + 1;
    console.log(flow, elementNumber);
    const finish = createNode({
      type: NodeType.FINISH,
      data: {
        name: NodeName.END_PATH,
        label: `Exit point ${elementNumber}`
      }
    }) as FinishNode;

    const nodes = flow.nodes.concat([finish]);
    const edges = flow.edges.concat([
      createEdge({ target: finish.id, source })
    ]);

    return FlowService.insertNodeBefore({ nodes, edges }, { source, target: finish.id }, node);
  },
  insertBranchBefore: (flow: Flow, { target }: Pick<FlowEdge, 'target'>, node: FlowNode): Flow => {
    const [source] = FlowService.getParentNodes(flow, target);

    if (!source) {
      return flow;
    }

    return FlowService.insertBranchAfter(flow, { source }, node);
  },
  removeNode: (flow: Flow, id: string): Flow => {
    const parent = flow.edges.find(({ target }) => target === id)?.source;
    const nodes = flow.nodes.filter(({ id: i }) => i !== id);
    const edges = flow.edges.filter(({ target, source }) => target !== id && source !== id).concat(
      flow.edges.filter(({ source }) => source === id).map(({ target }) => createEdge({ target, source: parent || '' })),
    );

    const parentChildren = FlowService.getChildren(parent, flow);
    const children = FlowService.getChildren(id, flow);
    let newFlow = { nodes, edges };

    if (parentChildren.length > 1 && children.length === 1 && children[0].data?.name === NodeName.END_PATH) {
      newFlow = FlowService.removeBranch(flow, id);
    }

    const node = flow.nodes.find(({ id: i }) => i === id);

    if (node.data.name === NodeName.AB_TEST || node.data.name === NodeName.GROUP_SPLIT) {
      const children = FlowService.getChildren(id, flow);

      children.forEach(child => {
        const childChildren = FlowService.getChildren(child.id, flow);

        if (childChildren.length === 1 && childChildren[0].data?.name === NodeName.END_PATH) {
          newFlow = FlowService.removeBranch(newFlow, child.id);
        } else {
          newFlow = FlowService.removeNode(newFlow, child.id);
        }
      });
    }

    return newFlow;
  },
  removeBranch: (flow: Flow, id: string): Flow => {
    const parentEdge = flow.edges.find(({ target }) => target === id);
    const parentId = parentEdge?.source;

    const nodesToRemove = new Set([id]);
    const edgesToRemove = new Set([`${parentId}->${id}`]);
    const edgesToAdd = [];

    const traverse = (currentId: string) => {
      flow.edges.forEach(({ source, target }) => {
        if (source === currentId && !nodesToRemove.has(target)) {
          nodesToRemove.add(target);
          edgesToRemove.add(`${source}->${target}`);
          traverse(target);
        }
      });
    };

    traverse(id);

    const siblingEdges = flow.edges.filter(({ source, target }) => source === parentId && !nodesToRemove.has(target));
    const hasSiblings = siblingEdges.length > 0;

    if (!hasSiblings && parentId) {
      const endPathNode = Array.from(nodesToRemove).find(nodeId => {
        const node = flow.nodes.find(({ id }) => id === nodeId);
        return node?.data.name === NodeName.END_PATH;
      });

      if (endPathNode) {
        nodesToRemove.delete(endPathNode);
        edgesToAdd.push(createEdge({ source: parentId, target: endPathNode }))
      }
    }

    return {
      nodes: flow.nodes.filter(node => !nodesToRemove.has(node.id)),
      edges: flow.edges.filter(edge => {
        const edgeKey = `${edge.source}->${edge.target}`;
        return !edgesToRemove.has(edgeKey);
      }).concat(edgesToAdd),
    };
  },
  changeNodeData: (flow: Flow, id: string, updater: ((f: FlowNodeData) => FlowNodeData) | FlowNodeData) => {
    let newFlow = {
      edges: flow.edges,
      nodes: flow.nodes.map(n => n.id === id ? { ...n, data: update(n.data, updater) } : n),
    };

    const node = newFlow.nodes.find(by(id));

    if (!node) {
      return flow;
    }

    if (node.data.name === NodeName.GROUP_SPLIT || node.data.name === NodeName.AB_TEST) {
      const previousSegments = FlowService.getChildren(id, flow).filter(({ data }) => data?.actionType !== 'exclude');
      const excludeSegment = FlowService.getChildren(id, flow).find(({ data }) => data?.actionType === 'exclude');

      if (excludeSegment) {
        newFlow = FlowService.changeNodeData(newFlow, excludeSegment.id, d => ({
          ...d,
          ...excludeSegment,
          order: 30000,
          name: node.data.name === NodeName.GROUP_SPLIT ? NodeName.EXCLUDE : NodeName.AB_SPLITTER,
        }));
      }

      node.data.segments.forEach((segment, index) => {
        const prevSegment = previousSegments.find(({ id }) => id === segment.id);

        if (prevSegment) {
          newFlow = FlowService.changeNodeData(newFlow, previousSegments[index].id, d => ({
            ...d,
            ...segment,
            order: index * 200,
            name: node.data.name === NodeName.GROUP_SPLIT ? NodeName.INCLUDE : NodeName.AB_SPLITTER,
          }));
        } else {
          newFlow = FlowService.insertBranchAfter(newFlow, { source: node.id }, createNode({
            type: NodeType.SPLITTER,
            data: {
              ...segment,
              order: index * 200,
              name: node.data.name === NodeName.GROUP_SPLIT ? NodeName.INCLUDE : NodeName.AB_SPLITTER,
            }
          }));
        }
      });

      previousSegments.filter(({ data }) => !node.data.segments.find(by(data.id))).forEach(segment => {
        newFlow = FlowService.removeBranch(newFlow, segment.id);
      });

      return newFlow;
    }

    return newFlow;
  },
  getEntryNode: (flow: Flow) => {
    return flow?.nodes?.find(n => n.data?.actionType === NodeActionType.ENTRY_POINT);
  },
  getControlGroupNode: (flow: Flow) => {
    return flow.nodes.find(n => n.data?.actionType === NodeActionType.CONTROL_GROUP)
  },
  getChildren: (id: string, flow: Flow) => {
    const edges = flow.edges.filter(by('source', id)).map(extract('target'));

    return flow.nodes.filter(({ id }) => edges.includes(id));
  },
  isAdditionBeforeAllowed: (flow: Flow, target: string) => {
    const node = flow.nodes.find(by(target));

    return node?.type !== NodeType.SPLITTER;
  },
  isAdditionBranchAllowed: (flow: Flow, target: string) => {
    const node = flow.nodes.find(by(target));

    return node?.data?.name !== NodeName.END_PATH;
  },
  isConnectionSourceAllowed: (flow: Flow, target: string) => {
    const node = flow.nodes.find(by(target));

    return node?.data?.name === NodeName.END_PATH;
  },
  isConnectionTargetAllowed: (flow: Flow, source: string, target: string) => {
    if (source === target) {
      return false;
    }

    const node = flow.nodes.find(by(target));

    return node?.data?.name === NodeName.END_PATH;
  },
  isOnlyTriggerSelected: (flow: Flow, source: string, target: string) => {
    const sourceNode = flow.nodes.find(by(source));
    const targetNode = flow.nodes.find(by(target));

    return EntryNodes.includes(sourceNode?.data?.name as NodeName) && targetNode?.data?.name === NodeName.END_PATH;
  },
  hasMultipleChildren: (flow: Flow, source: string) => {
    return flow.edges.filter(by('source', source)).length > 0;
  },
  isFirstEdge: (flow: Flow, source: string) => {
    return flow.nodes.find(({ data }) => data.actionType === NodeActionType.ENTRY_POINT)?.id === source;
  },
  isControlGroup: (flow: Flow, target: string) => {
    const node = flow.nodes.find(by(target));
    const [parentId] = FlowService.getParentNodes(flow, target);
    const parent = flow.nodes.find(by(parentId));

    return node?.data?.actionType === NodeActionType.CONTROL_GROUP || parent?.data?.actionType === NodeActionType.CONTROL_GROUP;
  },
  pasteBeforeAllowed: (flow: Flow, target: string) => {
    const node = flow.nodes.find(by(target));

    return node?.type !== NodeType.SPLITTER;
  },
  getParentNodes: (flow: Flow, id: string): string[] => {
    return flow.edges.filter(({ target }) => target === id).map(extract('source'));
  },
  createOrderByPosition: (flow: Flow, target: string, position: Position) => {
    const [parentId] = FlowService.getParentNodes(flow, target);
    const children = FlowService.getChildren(parentId, flow);

    const orders = children
      .map(({ id, data }) => ({ id, order: data.order }))
      .filter(({ order }) => typeof order === 'number')
      .sort((a, b) => a.order - b.order);

    let newOrder = flow.nodes.find(by(target))?.data?.order;

    if (position === Position.Right || position === Position.Bottom) {
      const targetOrder = flow.nodes.find(node => node.id === target)?.data.order || 0;

      const higherOrders = orders.filter(child => child.order > targetOrder);
      const nextHigherOrder = higherOrders[0]?.order;

      if (nextHigherOrder === undefined) {
        return targetOrder + 200;
      }

      newOrder = Math.ceil((targetOrder + nextHigherOrder) / 2);
    } else {
      const targetOrder = flow.nodes.find(node => node.id === target)?.data.order || 0;

      const lowerOrders = orders.filter(child => child.order < targetOrder);
      const nextLowerOrder = lowerOrders[lowerOrders.length - 1]?.order;

      if (nextLowerOrder === undefined) {
        return targetOrder - 200;
      }

      newOrder = Math.ceil((targetOrder + nextLowerOrder) / 2);
    }

    return newOrder;
  },
  isFirstSelection: (flow: Flow) => {
    if (flow.nodes.find(node => node.data?.actionType === NodeActionType.CONTROL_GROUP)) {
      return flow.nodes.length === 4;
    }

    return flow.nodes.length === 2;
  },
  isChildOf: (flow: Flow, parent: string, child: string): boolean => {
    const children = FlowService.getChildren(parent, flow);

    if (children.find(({ id }) => child === id)) {
      return true;
    }

    return children.some(c => FlowService.isChildOf(flow, c.id, child));
  },
  isFirstSending: (flow: Flow, parent: string, child: string): boolean => {
    const children = FlowService.getChildren(parent, flow);

    if (children.find(({ id, data }) => child === id && data.actionType === NodeActionType.CONNECTION_CHANNEL)) {
      return true;
    } else if (children.some(child => child.data.actionType === NodeActionType.CONNECTION_CHANNEL)) {
      return false;
    }

    return children.some(c => FlowService.isFirstSending(flow, c.id, child));
  },
  createFlowHash: (flow: Flow) => {
    return flow.nodes.map((node) => node.id + String(node.data?.state)).join('');
  },
  isValid: (flow: Flow) => {
    const onlyTriggerSelected = flow.nodes.filter((node: FlowNode) => node.data.name !== NodeName.END_PATH && node.data.actionType !== NodeActionType.CONTROL_GROUP)?.length === 1;

    return !onlyTriggerSelected && flow.nodes.filter((n: FlowNode) => !validNodesByDefault.includes(n.data?.name)).every((node: FlowNode) => node.data.state > 0 || node.data.type === 'include' || node.data.type === 'exclude' || node.data.type === 'ab');
  },
  getOptionEntities: (flow: Flow): Record<WorkflowOptionsType, Array<string | number>> => {
    const result:Record<WorkflowOptionsType, Array<string | number>> = {
      [WorkflowOptionsType.WEB_PUSH]: [],
      [WorkflowOptionsType.EMAIL]: [],
      [WorkflowOptionsType.SMS]: [],
      [WorkflowOptionsType.WORKFLOW]: [],
      [WorkflowOptionsType.MESSAGE_FEED]: [],
      [WorkflowOptionsType.MOBILE_PUSH]: [],
      [WorkflowOptionsType.RFM_ANALYTICS]: [],
      [WorkflowOptionsType.WEBHOOK]: [],
      [WorkflowOptionsType.VIBER]: [],
      [WorkflowOptionsType.WEB_POPUP]: [],
    };

    flow.nodes?.forEach((node: FlowNode) => {
      switch (node.data.name) {
        case NodeName.SEND_EMAIL:
        case NodeName.SEND_SMS:
        case NodeName.VIBER:
        case NodeName.WEBPUSH:
        case NodeName.MOBILE_PUSH:
        case NodeName.WEB_POPUP:
        case NodeName.MESSAGE_FEED:
        case NodeName.API_REQUEST:
          if (node.data[NodeNameTemplateFieldMap[node.data.name]]) {
            result[NodeNameTemplateTypeMap[node.data.name]].push(node.data[NodeNameTemplateFieldMap[node.data.name]]);
          }
          return;
        case NodeName.BEST_CHANNEL_TO_SEND:
          node.data.channels.forEach(channel => {
            if (channel.data[NodeNameTemplateFieldMap[channel.type]]) {
              result[NodeNameTemplateTypeMap[channel.type]].push(channel.data[NodeNameTemplateFieldMap[channel.type]]);
            }
          })
          return;
        case NodeName.RESOURCE: {
          const segmentsMetadata = node.data.segments_metadata;
          const rfmSegments = node.data?.segment_ids.filter(id => segmentsMetadata?.[id]?.parentId);

          if (rfmSegments?.length) {
            result[WorkflowOptionsType.RFM_ANALYTICS].push(...rfmSegments);
          }
          return;
        }
        case NodeName.GROUP_SPLIT: {
          const rfmSegments = (node?.data?.segments
            ?.filter?.(segment => segment?.filter_by === 'segment' && segment?.segmentType === 'rfm')
            ?.map?.(segment => segment.segment_id) || []) as string[];

          result[WorkflowOptionsType.RFM_ANALYTICS].push(...rfmSegments);
          return;
        }
        case NodeName.QUICK_FILTER:
        case NodeName.EXCLUDE_FILTER: {
          const rfmIds = node.data?.segment_id;

          if (rfmIds) {
            result[WorkflowOptionsType.RFM_ANALYTICS].push(rfmIds);
          }
          return;
        }
        default:
          return;
      }
    });

    return result;
  },
  createImage: async (getNodes) => {
    return new Promise(resolve => {
      requestAnimationFrame(() => {
        const IMG_WIDTH = 1024;
        const IMG_HEIGHT = 768;

        const nodesBounds = getNodesBounds(getNodes());
        const viewport = getViewportForBounds(
          nodesBounds,
          IMG_WIDTH,
          IMG_HEIGHT,
          0.1,
          1.4,
          0.2
        );

        const node = document.querySelector('.react-flow__viewport');

        toPng(node, {
          backgroundColor: '#fff',
          quality: 0.8,
          width: IMG_WIDTH,
          height: IMG_HEIGHT,
          style: {
            width: IMG_WIDTH,
            height: IMG_HEIGHT,
            transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
          },
          skipFonts: true,
          filter: (node) => {
            // Exclude scripts and other unnecessary nodes
            return node.tagName !== 'SCRIPT';
          },
        })
          .then(resolve)
          .catch(error => console.log('Image saving error', error));
      })
    })
  }
};
